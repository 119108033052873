import { ETC } from '../types';
export default {
  type: ETC,
  service: 'ETC Coop Parity WebSocket',
  url: 'wss://www.ethercluster.com/ws-etc',
  port: 443,
  auth: false,
  username: '',
  password: ''
};

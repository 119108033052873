var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mew-component--landing"},[_c('div',{staticClass:"desktop-content d-none d-lg-block expandHeader"},[_c('v-container',{staticClass:"\n        banner-content-container\n        d-flex\n        align-center\n        justify-space-between\n      "},[_c('v-card',{attrs:{"color":"transparent","flat":"","tile":"","max-width":"430px"}},[_c('div',{staticClass:"white--text mew-title"},[_vm._v("CPChain's Web Wallet")]),_c('p',{staticClass:"white--text mt-3"},[_vm._v(" CPChain Web Wallet is a free, client-side interface helping you interact with the CPChain blockchain. Our easy-to-use, open-source platform allows you to generate wallets, interact with smart contracts, and so much more. ")]),_c('div',{staticClass:"mt-9 d-flex"},[_c('mew-button',{staticClass:"mr-3",attrs:{"has-full-width":false,"title":"Create a new wallet","btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({
                name: _vm.ROUTES_HOME.CREATE_WALLET.NAME,
                params: {}
              })}}}),_c('mew-button',{attrs:{"has-full-width":false,"btn-style":"outline","title":"Access my wallet","btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({
                name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                params: {}
              })}}})],1)]),_c('img',{attrs:{"src":require("@/assets/images/backgrounds/bg-home-woman.png"),"alt":"Spaceman and his dog","height":"500"}})],1)],1),_c('div',{staticClass:"mobile-content d-block d-lg-none expandHeader pt-5"},[_c('v-container',[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"transparent","flat":"","tile":"","max-width":"400px"}},[_c('div',{staticClass:"white--text font-weight-bold mb-4",staticStyle:{"font-size":"3rem","line-height":"3.3rem"}},[_vm._v(" Ethereum's Original Wallet ")]),_c('p',{staticClass:"white--text mt-3"},[_vm._v(" MEW (MyEtherWallet) is a free, client-side interface helping you interact with the Ethereum blockchain. Our easy-to-use, open-source platform allows you to generate wallets, interact with smart contracts, and so much more. ")]),_c('div',{staticClass:"mt-12"},[_c('mew-button',{staticClass:"mb-3 width--full",attrs:{"has-full-width":false,"title":"Create a new wallet","btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({
                name: _vm.ROUTES_HOME.CREATE_WALLET.NAME,
                params: {}
              })}}}),_c('mew-button',{staticClass:"width--full",attrs:{"has-full-width":false,"btn-style":"outline","title":"Access my wallet","btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({
                name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                params: {}
              })}}})],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
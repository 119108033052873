export default {
  landingPage: 'landingPage',
  connectionType: 'connectionType',
  createWallet: 'createWallet',
  accessWallet: 'accessWallet',
  exitDashboard: 'exitDashboard',
  networkSwitch: 'networkSwitch',
  swap: 'swap',
  dapp: 'dapp',
  swapRates: 'swapRates'
};

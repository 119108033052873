import {
  ledgerCPChain,
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum
} from '../configs/configPaths';
export default [
  ledgerCPChain,
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum
];

import { CPC } from '../types';
export default {
  type: CPC,
  service: 'cpchain.io',
  url: 'https://civilian.cpchain.io',
  port: 443,
  auth: false,
  username: '',
  password: ''
};

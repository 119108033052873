import {
  keepkeyEthereum,
  keepkeyEthereumClassic,
  ropsten,
  singularDTV,
  expanse,
  ubiq,
  ellaism,
  etherGem,
  callisto,
  ethereumSocial,
  musicoin,
  goChain,
  eosClassic,
  akroma,
  etherSocialNetwork,
  pirl,
  ether1,
  atheios,
  tomoChain,
  mixBlockchain,
  iolite,
  thundercore,
  solidum,
  dexon,
  lightstreamsNetwork,
  rskMainnet,
  rskTestnet,
  ethercore
} from '../configs/configPaths';
export default [
  keepkeyEthereum,
  keepkeyEthereumClassic,
  ropsten,
  singularDTV,
  expanse,
  ubiq,
  ellaism,
  etherGem,
  callisto,
  ethereumSocial,
  musicoin,
  goChain,
  eosClassic,
  akroma,
  etherSocialNetwork,
  pirl,
  ether1,
  atheios,
  tomoChain,
  mixBlockchain,
  iolite,
  thundercore,
  solidum,
  dexon,
  lightstreamsNetwork,
  rskMainnet,
  rskTestnet,
  ethercore
];
